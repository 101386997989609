.App {
  
}

.App-header {
  background-color: #282c34;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-close .ui-dialog-titlebar-close {
  display: none;
}

.main-div {
 margin: 0px auto;
 max-width: 800px;
 min-width: 770px;
 
 
}

.card-div {
  margin: 10px;
  
}

.pos-card {
  width: auto;
  min-height: 60vh;

}

.pos-output {
  border-style: outset; 
}

.no-margin {
  margin: 0px;
}

.voffset1 {
  margin-top: 40px;
}

.hoffset1 {
  padding-left: 15px;
}

.center-align {
  text-align: center;
}

.box {
  display: flex;
  align-items: center;
  height: 30px;
}

.bold-font {
  font-weight: bold;
}

.no-border {
  border: none;
}

.med-size-font {
  font-size: 20px;
}

.no-padding-left {
  padding-left: 0px;
}

.warning {
  color: red;
  max-width: 350px;
}

.warning-long {
  color: red;
}

.pos-card button {
  padding-left: 20px;  
  padding-right: 20px;  
  margin-right: 4px;
}

